import React from "react";
import { withPrefix } from "gatsby";
import Layout from "../../components/Layout";
import "../../styles/global.css";
import "../../styles/generic.css";

function ContactPage() {
  return (
    <Layout>
      <main
        className="generic bg-image"
        style={{
          backgroundColor: "#2a2e35",
          backgroundImage: `url(${withPrefix("/bgmission.jpg")}`,
        }}
      >
        <div className="generic__container">
          <article className="generic__content">
            <h1>Contact</h1>
            <div className="generic__line" />
            <h3>General Inquiries</h3>
            <p>
              If you would like to learn more about Swiss Fire Protection R&D,
              have questions about possible joint collaborations and
              partnerships, or have media-related inquiries, please write or
              call us directly. Please note that we can only offer limited
              information about our technologies and their capabilities to
              non-clients. Thank you for your understanding.
            </p>
            <h3>Client Inquiries</h3>
            <p>
              If you are interested in learning more about one of our
              technologies or about a specific area of our research, please
              reach out to us.
            </p>
          </article>
          <div>
            <h3>Our address</h3>
            <p>
              <em>Swiss Fire Protection Research & Development AG</em>
              <br />
              <em>Kernserstrasse 17.</em>
              <br />
              <em>CH-6060 Sarnen, Switzerland</em>
            </p>
            <br />
            <p>+41 41 6624574</p>
            <br />
            <p>
              <em>info ‘at’ sfprd ‘dot’ com</em>
            </p>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default ContactPage;
